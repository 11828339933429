import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  IconButton,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth0 } from "@auth0/auth0-react";
import { sendEmail } from '../../utils/api/emailApi';
import { useSnackbar } from "../BaseComponents/GlobalErrorSnackbar";

const emptyUser = {
  firstName: '',
  lastName: '',
  email: '',
};

const validateEmails = (users) => {
  const errors = [];
  const errorFields = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const seenEmails = new Set();

  users.forEach((user, index) => {
    const email = user.email.trim();
    const fullName = `${user.firstName} ${user.lastName}`.trim();
    
    if (email) {
      if (!emailRegex.test(email)) {
        errors.push("Invalid email format found");
        errorFields[index] = true;
      }
      if (seenEmails.has(email.toLowerCase())) {
        errors.push(`${fullName}: Duplicate email address`);
        errorFields[index] = true;
      }
      seenEmails.add(email.toLowerCase());
    }
  });

  return { errors, errorFields };
};

function UserInvite({ open, onClose }) {
  const [users, setUsers] = useState([{ ...emptyUser }]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
  const { getAccessTokenSilently, user: authUser } = useAuth0();
  const { handleError, handleSuccess } = useSnackbar();
  const [emailErrors, setEmailErrors] = useState({});

  useEffect(() => {
    if (!open) {
      setUsers([{ ...emptyUser }]);
      setLoading(false);
      setAlert({ open: false, message: '', severity: 'success' });
    }
  }, [open]);

  useEffect(() => {
    return () => {
      // Cleanup on component unmount
      setUsers([{ ...emptyUser }]);
      setLoading(false);
      setAlert({ open: false, message: '', severity: 'success' });
      setEmailErrors({});
    };
  }, []);

  const handleAddRow = () => {
    setUsers([...users, { ...emptyUser }]);
  };

  const handleRemoveRow = (index) => {
    const newUsers = users.filter((_, i) => i !== index);
    setUsers(newUsers);
  };

  const handleChange = (index, field, value) => {
    const newUsers = [...users];
    newUsers[index] = { ...newUsers[index], [field]: value };
    setUsers(newUsers);

    // Only clear errors if they already exist and the email becomes valid
    if (field === 'email' && emailErrors[index]) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = value.trim() === '' || emailRegex.test(value);
      
      // Check for duplicates
      const isDuplicate = users.some((user, i) => 
        i !== index && 
        user.email.toLowerCase() === value.toLowerCase()
      );

      if (isValidEmail && !isDuplicate) {
        const newEmailErrors = { ...emailErrors };
        delete newEmailErrors[index];
        setEmailErrors(newEmailErrors);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setEmailErrors({});

    try {
      const token = await getAccessTokenSilently();
      
      // Filter out empty rows
      const validUsers = users.filter(user => 
        user.firstName.trim() !== '' && 
        user.lastName.trim() !== '' && 
        user.email.trim() !== ''
      );

      // If no valid users, show message and return
      if (validUsers.length === 0) {
        setAlert({
          open: true,
          message: 'Please input details for at least one user before submitting',
          severity: 'warning'
        });
        setLoading(false);
        return;
      }

      // Validate emails
      const { errors, errorFields } = validateEmails(validUsers);
      if (errors.length > 0) {
        setEmailErrors(errorFields);
        setAlert({
          open: true,
          message: errors.join('\n'),
          severity: 'error'
        });
        setLoading(false);
        return;
      }

      await Promise.all(validUsers.map(async (user) => {
        const signupUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/authorize?` +
          `response_type=code&` +
          `client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&` +
          `redirect_uri=${encodeURIComponent(window.location.origin)}&` +
          `screen_hint=signup&` +
          `email=${encodeURIComponent(user.email)}`;

        const emailData = {
          to: user.email,
          subject: 'Invitation to FoodTrails Platform',
          content: `
<div style="font-family: Arial, sans-serif; line-height: 1.6; max-width: 600px; margin: 0 auto;">
  <p>Dear ${user.firstName} ${user.lastName},</p>

  <p>You have been invited by ${authUser.name} to join the FoodTrails Platform.</p>

  <p style="margin: 25px 0;">
    <a href="${signupUrl}" style="background-color: #1976d2; color: white; padding: 10px 20px; text-decoration: none; border-radius: 4px; display: inline-block;">
      Click here to join!
    </a>
  </p>

  <p style="color: #666; font-size: 0.9em;">Note: This link will expire in 24 hours.</p>

  <p style="margin-top: 25px;">
    Best regards,<br>
    The FoodTrails Team
  </p>
</div>`
        };

        await sendEmail(emailData, token);
      }));

      handleSuccess('Invitations sent successfully!');
      
      setUsers([{ ...emptyUser }]);
      onClose();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="md"
        fullWidth
        keepMounted={false}
        disablePortal
      >
        <DialogTitle sx={{ pb: 2 }}>
          Invite Users
        </DialogTitle>
        
        <DialogContent sx={{ 
          pt: 4,  // Increased padding top even more
          px: 3,
          pb: 2 
        }}>
          <form 
            onSubmit={handleSubmit} 
            style={{ marginTop: '8px' }}
            data-form-type="other"
          >
            {users.map((user, index) => (
              <Grid 
                container 
                spacing={2} 
                key={index} 
                sx={{ 
                  mb: 1  // Reduced from mb: 2 to mb: 1
                }}
              >
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="First Name"
                    value={user.firstName}
                    onChange={(e) => handleChange(index, 'firstName', e.target.value)}
                    required
                    sx={{ mt: 0.5 }}
                    autoComplete="off"
                    inputProps={{
                      'data-form-type': 'other',
                      'data-lpignore': 'true'
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    value={user.lastName}
                    onChange={(e) => handleChange(index, 'lastName', e.target.value)}
                    required
                    sx={{ mt: 0.5 }}
                    autoComplete="off"
                    inputProps={{
                      'data-form-type': 'other',
                      'data-lpignore': 'true'
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    value={user.email}
                    onChange={(e) => handleChange(index, 'email', e.target.value)}
                    required
                    error={!!emailErrors[index]}
                    helperText={emailErrors[index] ? "Invalid email format" : " "}
                    autoComplete="off"
                    inputProps={{
                      'data-form-type': 'other',
                      'data-lpignore': 'true'
                    }}
                    sx={{ 
                      mt: 0.5,
                      '& .MuiFormHelperText-root': {
                        transition: 'opacity 0.3s ease',
                        opacity: emailErrors[index] ? 1 : 0,
                        height: '1.2em',
                        margin: '4px 0'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  {users.length > 1 && (
                    <IconButton 
                      onClick={() => handleRemoveRow(index)}
                      color="error"
                      sx={{ mt: 0.5 }}  // Reduced from mt: 1 to mt: 0.5
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
          </form>
        </DialogContent>

        <DialogActions sx={{ padding: 2, justifyContent: 'flex-end', gap: 2 }}>
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddRow}
            variant="outlined"
            color="primary"
            disabled={loading}
            sx={{ borderRadius: 2 }}
          >
            Add Another User
          </Button>
          <Button 
            onClick={onClose} 
            disabled={loading}
            variant="outlined"
            color="primary"
            sx={{ borderRadius: 2 }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ borderRadius: 2 }}
          >
            {loading ? 'Sending...' : 'Send Invites'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={alert.open} 
        autoHideDuration={6000} 
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseAlert} 
          severity={alert.severity}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default React.memo(UserInvite);
