const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const categorizeAllProducts = async (token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/admin/categorize_products`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to start product categorization');
    }

    return await response.json();
  } catch (error) {
    console.error('Error starting product categorization:', error);
    throw error;
  }
};

export const categorizeUncategorizedProducts = async (token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/admin/categorize_uncategorized_products`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to start uncategorized product categorization');
    }

    return await response.json();
  } catch (error) {
    console.error('Error starting uncategorized product categorization:', error);
    throw error;
  }
}; 