import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Box,
  Divider,
  CircularProgress,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Stack,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { FacilityList } from "./SubComponents/FacilityList";
import { useFacilities } from "../../context/FacilitiesContext";
import { useSnackbar } from "../BaseComponents/GlobalErrorSnackbar";
import { useAuth0 } from "@auth0/auth0-react";
import { generateReport } from "../../utils/api/purchasesApi";
import ClearIcon from '@mui/icons-material/Clear';
import debounce from 'lodash/debounce';

const GenerateReport = () => {
  const [startDate, setStartDate] = useState(() => dayjs().subtract(90, "day"));
  const [isDateManual, setIsDateManual] = useState(false);
  const [endDate, setEndDate] = useState(() => dayjs());
  const [selectedDateRange, setSelectedDateRange] = useState("90");
  const [selectAll, setSelectAll] = useState(false);
  const { facilities, setFacilities, loading: facilitiesLoading } = useFacilities();
  const { handleWarning, handleSuccess } = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();
  const [reportTypeError, setReportTypeError] = useState(false);
  const inputBoxRef = useRef(null);
  const reportTypes = useMemo(() => [
    { value: "spend_by_locality_with_purchases", label: "Spend by Locality -- Full Purchase Details by Line Item" },
    { value: "spend_by_locality", label: "Spend by Locality" },
    { value: "local_purchases_only", label: "Local Purchases Only" },
    { value: "regional_purchases_only", label: "Regional Purchases Only" },
    { value: "purchases_by_supplier", label: "Purchases by Supplier" },
  ], []);
  const [reportType, setReportType] = useState(reportTypes[0].value);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredFacilities, setFilteredFacilities] = useState([]);
  const [expandedFacilities, setExpandedFacilities] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);

  const debouncedSetExpandedFacilities = useMemo(
    () => debounce((newExpandedFacilities) => {
      setExpandedFacilities(newExpandedFacilities);
    }, 300),
    []
  );

  const isDateInvalid = useMemo(() => 
    endDate.isBefore(startDate), [startDate, endDate]
  );

  // Ensures facilities are unchecked when the component is unmounted
  useEffect(() => {
    return () => {
      setFacilities(prevFacilities => prevFacilities.map(facility => ({
        ...facility,
        checked: false,
        children: facility.children.map(child => ({
          ...child,
          checked: false
        }))
      })));
    };
  }, [setFacilities]);

  // Ensures a preset date range is selected if a user manually enters
  // a corresponding date
  useEffect(() => {
    if (isDateManual) {
      setSelectedDateRange(endDate.isSame(dayjs(), "day") 
        ? endDate.diff(startDate, "days").toString()
        : null
      );
    }
  }, [startDate, endDate, isDateManual]);

  // Add this new useEffect hook to filter facilities based on search term
  useEffect(() => {
    const filtered = facilities.map(facility => {
      const facilityMatches = facility.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                              facility.ft_name.toLowerCase().includes(searchTerm.toLowerCase());
      const matchingChildren = facility.children.filter(child =>
        child.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        child.ft_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      
      return {
        ...facility,
        children: searchTerm ? (facilityMatches ? facility.children : matchingChildren) : facility.children
      };
    }).filter(facility =>
      searchTerm ? (
        facility.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        facility.ft_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        facility.children.length > 0
      ) : true
    );
    setFilteredFacilities(filtered);
  }, [facilities, searchTerm]);

  useEffect(() => {
    return () => {
      debouncedSetExpandedFacilities.cancel();
    };
  }, [debouncedSetExpandedFacilities]);

  const handleDateChange = useCallback((newDate, field) => {
    if (field === "start") {
      setStartDate(newDate);
    } else if (field === "end") {
      setEndDate(newDate);
    }
    setIsDateManual(true);
  }, []);

  const handleSelectAll = useCallback((event) => {
    const newCheckedState = event.target.checked;
    setSelectAll(newCheckedState);

    setFacilities(prevFacilities => prevFacilities.map(facility => ({
      ...facility,
      checked: newCheckedState,
      children: facility.children.map(child => ({
        ...child,
        checked: newCheckedState
      }))
    })));
  }, [setFacilities]);

  const handleFacilityChange = useCallback((facilityId) => {
    setFacilities(prevFacilities => {
      const newFacilities = prevFacilities.map(facility => {
        if (facility.id === facilityId) {
          const newCheckedState = !facility.checked;
          return {
            ...facility,
            checked: newCheckedState,
            children: facility.children.map(child => ({
              ...child,
              checked: newCheckedState,
            })),
          };
        } else if (facility.children.some(child => child.id === facilityId)) {
          const updatedChildren = facility.children.map(child =>
            child.id === facilityId ? { ...child, checked: !child.checked } : child
          );
          return {
            ...facility,
            checked: updatedChildren.every(child => child.checked),
            children: updatedChildren,
          };
        }
        return facility;
      });
      
      const allChecked = newFacilities.every(facility => 
        facility.checked && facility.children.every(child => child.checked)
      );
      setSelectAll(allChecked);
      
      return newFacilities;
    });
  }, []);

  const handleDatePreset = useCallback((days, rangeValue) => {
    setStartDate(dayjs().subtract(days, "day"));
    setEndDate(dayjs());
    setIsDateManual(false);
    setSelectedDateRange(rangeValue);
  }, []);

  const handleCurrentSchoolYear = useCallback(() => {
    const schoolYearStart = dayjs().month(6).date(1);
    setStartDate(dayjs().isBefore(schoolYearStart) || dayjs().isSame(schoolYearStart)
      ? schoolYearStart.year(dayjs().year() - 1)
      : dayjs().month(6).date(1)
    );
    setEndDate(dayjs());
    setIsDateManual(false);
    setSelectedDateRange("currSchoolYear");
  }, []);

  const handlePreviousSchoolYear = useCallback(() => {
    const schoolYearStart = dayjs().month(6).date(1);
    const isBeforeOrSameSchoolYearStart = dayjs().isBefore(schoolYearStart) || dayjs().isSame(schoolYearStart);
    setStartDate(schoolYearStart.year(dayjs().year() - (isBeforeOrSameSchoolYearStart ? 2 : 1)));
    setEndDate(dayjs().year(dayjs().year() - (isBeforeOrSameSchoolYearStart ? 1 : 0)).month(5).date(30));
    setIsDateManual(false);
    setSelectedDateRange("prevSchoolYear");
  }, []);

  const scrollToReportBox = useCallback(() => {
    if (inputBoxRef.current) {
      const yOffset = -150;
      const y = inputBoxRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, []);

  const scrollToDateBox = useCallback(() => {
    if (inputBoxRef.current) {
      const yOffset = -75;
      const y = inputBoxRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, []);

  const handleGenerateReport = useCallback(async () => {
    const selectedFacilityIds = facilities.flatMap(facility => {
      const selectedChildIds = facility.children
        .filter(child => child.checked)
        .map(child => child.id);
      
      return facility.checked ? [facility.id, ...selectedChildIds] : selectedChildIds;
    });

    if (!reportType) {
      handleWarning(Error("Please select a report type."));
      setReportTypeError(true);
      scrollToReportBox();
      return;
    }

    if (isDateInvalid) {
      handleWarning(Error("Ensure End Date is not earlier than Start Date."));
      scrollToDateBox();
      return;
    }

    if (selectedFacilityIds.length === 0) {
      handleWarning(Error("Please select at least one facility."));
      return; 
    }

    setReportTypeError(false);

    try {
      setIsGenerating(true);
      const token = await getAccessTokenSilently();
      await generateReport(token, startDate, endDate, selectedFacilityIds, reportType);
      handleSuccess("Report generated successfully!");
    } catch (error) {
      console.error("Error generating report:", error);
      handleWarning(Error("Failed to generate report. Please try again."));
    } finally {
      setIsGenerating(false);
    }
  }, [facilities, isDateInvalid, handleWarning, handleSuccess, scrollToDateBox, scrollToReportBox, startDate, endDate, reportType, getAccessTokenSilently]);

  const handleReportTypeChange = useCallback((e) => {
    setReportType(e.target.value);
    setReportTypeError(false);
  }, []);

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setFilteredFacilities(facilities);
    setExpandedFacilities([]);
  };

  return (
    <Box sx={{ margin: 4, maxWidth: 600, mx: "auto" }}>
      <Typography variant="h4" align="center" sx={{ mb: 4, fontWeight: "bold" }}>
        Generate Report
      </Typography>
      <Box ref={inputBoxRef} sx={{ height: 0, overflow: 'hidden' }}></Box>
      <Stack spacing={3}>
        <TextField
          select
          label="Report Type"
          value={reportType}
          onChange={handleReportTypeChange}
          fullWidth
          id="report-type"
          name="report-type"
          error={reportTypeError}
          helperText={reportTypeError ? "Please select a report type" : ""}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: reportTypeError ? 'red' : 'inherit',
              },
              '&:hover fieldset': {
                borderColor: reportTypeError ? 'red' : 'inherit',
              },
              '&.Mui-focused fieldset': {
                borderColor: reportTypeError ? 'red' : 'primary.main',
              },
            },
            '& .MuiInputLabel-root': {
              color: reportTypeError ? 'red' : 'inherit',
            },
            '& .MuiSelect-icon': {
              color: reportTypeError ? 'red' : 'inherit',
            },
          }}
        >
          {reportTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newDate) => handleDateChange(newDate, "start")}
            slotProps={{
              textField: {
                error: isDateInvalid,
                helperText: isDateInvalid ? "Ensure Start Date is before End Date" : ""
              },
            }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: isDateInvalid ? 'red !important' : 'inherit',
              },
              '& .MuiInputLabel-root': {
                color: isDateInvalid ? 'red !important' : 'inherit',
              },
              '& .MuiIconButton-root': {
                color: isDateInvalid ? 'red !important' : 'inherit',
              },
            }}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newDate) => handleDateChange(newDate, "end")}
            slotProps={{
              textField: {
                error: isDateInvalid,
                helperText: isDateInvalid ? "Ensure End Date is after Start Date" : ""
              },
            }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: isDateInvalid ? 'red !important' : 'inherit',
              },
              '& .MuiInputLabel-root': {
                color: isDateInvalid ? 'red !important' : 'inherit',
              },
              '& .MuiIconButton-root': {
                color: isDateInvalid ? 'red !important' : 'inherit',
              },
            }}
          />
        </LocalizationProvider>
        <Box
          sx={{
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "16px",
            position: "relative",
          }}
        >
          <FormLabel
            component="legend"
            sx={{
              position: "absolute",
              top: "-10px",
              left: "16px",
              backgroundColor: "white",
              padding: "0 4px",
            }}
          >
            Preset Date Ranges
          </FormLabel>
          <FormControl component="fieldset">
            <RadioGroup row value={selectedDateRange}>
              <FormControlLabel
                value="90"
                control={<Radio />}
                label="Last 90 Days"
                onClick={() => handleDatePreset(90, "90")}
              />
              <FormControlLabel
                value="180"
                control={<Radio />}
                label="Last 180 Days"
                onClick={() => handleDatePreset(180, "180")}
              />
              <FormControlLabel
                value="365"
                control={<Radio />}
                label="Last Year"
                onClick={() => handleDatePreset(365, "365")}
              />
              <FormControlLabel
                value="currSchoolYear"
                control={<Radio />}
                label="Current School Year"
                onClick={handleCurrentSchoolYear}
              />
              <FormControlLabel
                value="prevSchoolYear"
                control={<Radio />}
                label="Previous School Year"
                onClick={handlePreviousSchoolYear}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box
          sx={{
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "16px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <FormLabel
            component="legend"
            sx={{
              position: "absolute",
              top: "-10px",
              left: "16px",
              backgroundColor: "white",
              padding: "0 4px",
            }}
          >
            Schools
          </FormLabel>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  id="select-all-facilities"
                  name="select-all-facilities"
                />
              }
              label="Select All"
            />
            <TextField
              placeholder="Search facilities..."
              size="small"
              sx={{ width: "75%" }}
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchTerm && (
                      <IconButton
                        aria-label="clear search"
                        onClick={handleClearSearch}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Divider variant="middle" />
          <FormGroup sx={{ maxHeight: 240, overflowY: 'auto' }}>
            {facilitiesLoading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <FacilityList
                facilities={filteredFacilities.length > 0 ? filteredFacilities : facilities}
                handleFacilityChange={handleFacilityChange}
                expandedFacilities={expandedFacilities}
                setExpandedFacilities={setExpandedFacilities}
                searchTerm={searchTerm}
              />
            )}
          </FormGroup>
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={handleGenerateReport}
          disabled={isGenerating}
          startIcon={isGenerating && <CircularProgress size={20} color="inherit" />}
        >
          {isGenerating ? "Generating Report..." : "Download Report (CSV)"}
        </Button>
      </Stack>
    </Box>
  );
};

export default GenerateReport;