import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

export const isSuperAdmin = (email) => email === 'contact@foodtrails.org';

const RequireSuperAdminRole = ({ children }) => {
  const { user } = useAuth0();

  if (!isSuperAdmin(user?.email)) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export default RequireSuperAdminRole;
