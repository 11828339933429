import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import HomeIcon from "@mui/icons-material/Home";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InventoryIcon from '@mui/icons-material/Inventory';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Divider from "@mui/material/Divider";
import { useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { useRole } from "../RoleProvider";
import { isSuperAdmin } from '../RequireSuperAdminRole';

const drawerWidth = 240;

const Sidebar = () => {
  const location = useLocation();
  const { hasAdminRole } = useRole();
  const { user } = useAuth0();

  const menuItems = [
    { text: "Dashboard", icon: <HomeIcon />, path: "/dashboard" },
    { text: "Data Upload", icon: <CloudUploadIcon />, path: "/upload" },
    { text: "Products", icon: <InventoryIcon />, path: "/products" },
    { text: "Submit Feedback", icon: <HelpOutlineIcon />, path: "/feedback" },
  ];

  if (hasAdminRole) {
    menuItems.unshift(
      { text: "Admin", icon: <AdminPanelSettingsIcon />, path: "/admin" }
    )
  }

  if (isSuperAdmin(user?.email)) {
    menuItems.unshift(
      { text: "Super Admin", icon: <SupervisorAccountIcon />, path: "/super-admin" }
    )
  }

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          paddingRight: "8px",
          bgcolor: "#F5F5F5",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Divider />
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            component={Link}
            to={item.path}
            selected={location.pathname.startsWith(item.path)}
            sx={{
              flexDirection: "column",
              alignItems: "center", 
              borderRadius: 10,
              padding: "10px", 
              "&:hover": {
                backgroundColor: "action.hover",
              },
              "&.Mui-selected": {
                backgroundColor: "primary.main",
                color: "common.white",
                "& .MuiListItemIcon-root": {
                  color: "inherit", 
                },
                "&:hover": {
                  bgcolor: "primary.light", 
                  color: "white",
                },
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: "auto", justifyContent: "center" }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} disableTypography>
              <Typography variant="caption" sx={{ color: "inherit" }}>
                {item.text}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;