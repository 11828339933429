import React from 'react';
import { Box, Button, Typography, Paper } from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from '../BaseComponents/GlobalErrorSnackbar';
import { categorizeAllProducts, categorizeUncategorizedProducts } from '../../utils/api/adminApi';

const HomePage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { handleSuccess, handleError } = useSnackbar();

  const handleCategorizeAll = async () => {
    try {
      const token = await getAccessTokenSilently();
      await categorizeAllProducts(token);
      handleSuccess('Product categorization started. You will receive an email when complete.');
    } catch (error) {
      console.error('Error:', error);
      handleError(error);
    }
  };

  const handleCategorizeUncategorized = async () => {
    try {
      const token = await getAccessTokenSilently();
      await categorizeUncategorizedProducts(token);
      handleSuccess('Uncategorized products categorization started. You will receive an email when complete.');
    } catch (error) {
      console.error('Error:', error);
      handleError(error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Super Admin Dashboard
      </Typography>
      
      <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
        <Paper 
          elevation={3} 
          sx={{ 
            p: 3, 
            flex: 1, 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            gap: 2 
          }}
        >
          <CategoryIcon sx={{ fontSize: 40, color: 'primary.main' }} />
          <Typography variant="h6">Categorize All Products</Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            Categorize all products in the database using AI
          </Typography>
          <Button 
            variant="contained" 
            onClick={handleCategorizeAll}
            startIcon={<CategoryIcon />}
          >
            Start Categorization
          </Button>
        </Paper>

        <Paper 
          elevation={3} 
          sx={{ 
            p: 3, 
            flex: 1, 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            gap: 2 
          }}
        >
          <NewReleasesIcon sx={{ fontSize: 40, color: 'primary.main' }} />
          <Typography variant="h6">Categorize Uncategorized</Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            Only categorize products that haven't been categorized yet
          </Typography>
          <Button 
            variant="contained" 
            onClick={handleCategorizeUncategorized}
            startIcon={<NewReleasesIcon />}
          >
            Start Categorization
          </Button>
        </Paper>
      </Box>
    </Box>
  );
};

export default HomePage;
