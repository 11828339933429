const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const sendEmail = async (emailData, token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/emails/send_email`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: emailData })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to send email');
    }

    return await response.json();
  } catch (error) {
    console.error('Error sending email:', error);
    error.message = 'Error sending email (check console for full error)';
    throw error;
  }
};
